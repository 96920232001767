'use client'
import useMeta from '@/store/meta'
import SCP_IconButtonCenterGroup from '../center/IconButtonCenterGroup'
import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import { useBreakpoint } from '@/utils/responsive'

export default function CCP_HomeServicesSwiper({
  mobileReadmore,
  centerGroupIds,
}) {
  const { centerGroups } = useMeta()
  const isMd = useBreakpoint('md')
  let highlightedCentersGroup = []
  // If centerGroupIds is provided, filter the centerGroups by the ids
  if (centerGroupIds) {
    highlightedCentersGroup = centerGroups?.filter((x) =>
      centerGroupIds.includes(x._id)
    )
  } else {
    highlightedCentersGroup = centerGroups
      ?.filter((x) => x.highlight && x.title)
      .slice(0, 14)
  }
  // Fill in array to 14 for swiper grid (desktop)
  while (highlightedCentersGroup?.length < 14 && isMd) {
    highlightedCentersGroup.push(0)
  }

  return (
    <div className="tw-col-span-2">
      <CCP_MySwiper
        breakpoints={{
          768: {
            slidesPerView: 6.5,
          },
          1080: {
            slidesPerView: 7,
            grid: {
              rows: 2,
              fill: 'row',
            },
            spaceBetween: 36,
          },
        }}
        slidesPerView={4.3}
        spaceBetween={24}
        swiperClass="related-swiper"
        watchSlidesProgress={true}
        pagination={{
          clickable: true,
        }}
        skeleton={
          <div className="tw-grid sm:tw-grid-cols-7 tw-grid-cols-4 sm:tw-gap-9 tw-gap-6">
            {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]?.map((post, i) => (
              <SCP_IconButtonCenterGroup
                key={`center-group-card-skeleton-${i}`}
                isLoading={true}
                addedClass={`${i > 6 ? 'max-md:tw-hidden' : ''} ${i > 3 ? 'max-sm:tw-hidden' : ''}`}
              />
            ))}
          </div>
        }
        slides={highlightedCentersGroup?.map((post, i) => (
          <SCP_IconButtonCenterGroup
            key={`center-group-card-${i}`}
            centerGroup={post}
            isLoading={false}
          />
        ))}
        mobileReadmore={mobileReadmore}
      />
    </div>
  )
}
