import { Link } from '@/navigation'
import SCP_BlurImage from '../element/BlurImage'
import SimpleButton from '../button/SimpleButton'

export default function SCP_ArabicOverlapseCard({ slide }) {
  return (
    <div className="tw-grid tw-grid-cols-12 max-md:tw-p-4 tw-bg-[hsla(217,100%,91%,1)] sm:tw-rounded-[40px] tw-rounded-[20px]">
      {/* Detail */}
      <div
        id="card-detail-wrapper"
        className="md:tw-p-12 !tw-pl-0 sm:tw-p-8 md:tw-col-span-6 tw-col-span-12"
      >
        {/* Title */}
        <h3 className="tw-pt-4">{slide?.title}</h3>
        {/* Excerpt */}
        <p className="sm:tw-mt-8 tw-mt-4 tw-text-primary tw-text-sm tw-line-clamp-2">
          {slide?.excerpt}
        </p>
        {/* Readmore */}
        {slide?.button?.text && slide?.button?.link ? (
          <Link href={slide?.button?.link}>
            <SimpleButton
              addedClass="sm:tw-mt-16 tw-mt-12 tw-mr-auto bg-gradient-arabic-blue-button !tw-rounded-full"
              label={slide?.button?.text}
            />
          </Link>
        ) : null}
      </div>
      {/* Image */}
      <div className="md:tw-col-span-6 tw-col-span-12 tw-relative tw-z-10 sm:tw-pr-12 tw--mt-16 max-md:tw-order-first">
        <SCP_BlurImage
          src={slide?.image?.url}
          alt="Arabic CTA image"
          addedClass="sm:tw-rounded-[30px] sm:tw-rounded-br-[100px] tw-rounded-[20px] tw-rounded-br-[40px] tw-arabic-slide-image"
        />
      </div>
    </div>
  )
}
