'use client'
import CCP_AppointmentUserHNProvider from '@/components/main/appointment/AppointmentUserHNProvider'
import { Link } from '@/navigation'
import useAuth from '@/store/auth'
import useMeta from '@/store/meta'
import countRemainingChallenges from '@/utils/fpd/countRemainingChallenges'
import getUserDisplayName from '@/utils/fpd/getUserDisplay'
import { Skeleton } from '@mui/material'
import { useLocale, useTranslations } from 'next-intl'
import SCP_CoinDisplay from '../b-point/widgets/CoinDisplay'
import SCP_AnimateArrow from '../element/AnimateArrow'
import CCP_MyAppointmentProvider from '../my-account/MyAppointmentProvider'
import CCP_HomeBannerUpcomingAppointments from './HomeBannerUpcomingAppointment'

export default function SCP_HomeBannerOverview({
  isLoading = false,
  addedClass = '',
}) {
  const __t = useTranslations()
  const locale = useLocale()
  const { userMe } = useAuth()
  const { metaSettings } = useMeta()
  const signupPoints = metaSettings?.fpd_ot_register_point_to_earn || 0
  const points = userMe?.my_b_point?.remaining_points
  const remainingChallenges = userMe?.remaining_challenges
  const remainingChallengesNum = countRemainingChallenges(remainingChallenges)

  return (
    <div className={`tw-col-span-2 ${addedClass}`}>
      <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-px-5 max-md:tw-p-5">
        {isLoading ? (
          <>
            <Skeleton variant="text" width="30%" className="!tw-text-xl" />
            <Skeleton variant="text" width="50%" className="!tw-text-3xl" />
          </>
        ) : (
          <div className="tw-grid tw-grid-cols-3 tw-gap-2">
            {/* Left */}
            <div className="tw-col-span-2">
              {userMe === null ? (
                <>
                  <Skeleton
                    variant="text"
                    width="20%"
                    className="!tw-text-lg"
                  />
                  <Skeleton
                    variant="text"
                    width="100%"
                    className="!tw-text-4xl"
                  />
                </>
              ) : (
                <>
                  {/* Greeting message */}
                  <p className="tw-text-bgh-gray-primary tw-text-sm">
                    {userMe
                      ? __t('greeting-message')
                      : signupPoints
                        ? __t('sign-up-get-points', { points: signupPoints })
                        : null}
                  </p>
                  {/* CTA */}
                  <Link
                    aria-label={`${userMe ? __t('my-appointment') : __t('login-registration')}`}
                    prefetch={false}
                    href={userMe ? '/my-account/appointment' : '/auth/login'}
                    className="tw-flex tw-items-center  tw-space-x-2 tw-group"
                  >
                    {/* Message */}
                    <p className="tw-text-2xl tw-text-primary tw-max-w-[80%] tw-overflow-hidden tw-line-clamp-1 tw-break-all">
                      {userMe
                        ? getUserDisplayName(userMe, locale)
                        : __t('register-now')}
                    </p>
                    {/* Animate Arrow */}
                    <SCP_AnimateArrow addedClass="group-hover:ltr:tw-translate-x-2 group-hover:rtl:-tw-translate-x-2 rtl:tw-rotate-180" />
                  </Link>
                </>
              )}
              {/* Appointments */}
              {userMe ? (
                <CCP_AppointmentUserHNProvider>
                  <CCP_MyAppointmentProvider
                    height={20}
                    width={160}
                    showErrorState={false}
                    showLoadingState={false}
                  >
                    <CCP_HomeBannerUpcomingAppointments />
                  </CCP_MyAppointmentProvider>
                </CCP_AppointmentUserHNProvider>
              ) : null}
            </div>
            {/* Right */}
            <div className="tw-col-span-1 tw-flex tw-flex-col tw-items-end tw-space-y-1">
              {/* Points */}
              {points ? (
                <Link
                  prefetch={false}
                  href={`/my-account/b-point`}
                  aria-label={`${__t('go-to')} ${__t('my-b-point')}`}
                >
                  <SCP_CoinDisplay points={points} type="c" />
                </Link>
              ) : (
                <SCP_CoinDisplay showInfo={true} points={'Point'} type="c" />
              )}
              {/* Challenges */}
              {remainingChallengesNum ? (
                <Link
                  prefetch={false}
                  href={`/my-account/b-point?goto=challenges`}
                >
                  <p
                    dir="ltr"
                    className="tw-text-xs tw-text-bgh-fpd-blue-bright tw-text-right tw-whitespace-nowrap"
                  >
                    {__t('challenges-left', {
                      challenges: remainingChallengesNum,
                    })}
                  </p>
                </Link>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
