import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/arabic/ArabicPackage.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/arabic/ArabicSwiperOverlapse.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/components/main/element/ImageFallback.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/components/main/element/TransitionImageChild.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/home/HomeAboutNetworkHospital.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/components/main/home/HomeBannerOverview.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/components/main/home/HomeServicesSwiper.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/package/PackageHighlightButtons.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/components/main/swiper/MySwiper.jsx");
