'use client'

import CCP_ImageFallback from '@/components/main/element/ImageFallback'
import CCP_TransitionImageChild from '../element/TransitionImageChild'
import { useEffect, useState } from 'react'
import { Link } from '@/navigation'

export default function CCP_ArabicPackage({ content }) {
  const [activeMenu, setActiveMenu] = useState(0)
  useEffect(() => {
    const menuLength = content?.menus?.length
    const interval = setInterval(() => {
      setActiveMenu((prevActiveMenu) => (prevActiveMenu + 1) % menuLength)
    }, 5000)
    return () => clearInterval(interval)
  }, [content?.menus?.length])
  if (!content || !content?.menus || content?.menus?.length === 0) return null

  return (
    <div className="tw-container sm:tw-py-20 tw-py-12 tw-grid sm:tw-grid-cols-2 md:tw-gap-16 sm:tw-gap-10 tw-gap-8">
      {/* Image */}
      <div>
        <h3 className="animated-underline max-sm:tw-text-center max-sm:tw-w-full">
          {content?.service_heading}
        </h3>
        <div className="tw-pb-sq tw-w-full t-h-0 tw-relative tw-rounded-[20px] tw-overflow-hidden tw-mt-12 tw-bg-bgh-skeleton">
          <CCP_ImageFallback
            alt="Arabic package image"
            src={content?.menus[activeMenu]?.image?.url}
            fill={true}
            className="tw-object-cover"
          />
        </div>
      </div>
      {/* Menu */}
      <div className="bg-gradient-arabic-blue-radial tw-rounded-[20px] sm:!tw-rounded-br-[120px] !tw-rounded-br-[60px] tw-shadow-main-blue tw-py-4 tw-flex tw-flex-col tw-justify-between tw-items-end">
        {/* List */}
        {content?.menus ? (
          <div className="tw-flex tw-flex-col tw-w-full">
            {content?.menus?.map((item, i) => (
              <div
                key={`arabic-package-${i}`}
                onMouseEnter={() => setActiveMenu(i)}
              >
                <Link
                  href={item?.link || '/'}
                  className={`tw-grid tw-grid-cols-5 tw-items-center tw-gap-4 sm:tw-py-4 tw-py-2 sm:tw-px-12 tw-px-6 hover:tw-bg-white/60 tw-duration-200 tw-cursor-pointer
              ${activeMenu === i ? 'tw-bg-white/60' : ''}
              ${item?.link ? '' : 'tw-pointer-events-none'}
              `}
                >
                  {/* Text */}
                  <div className="tw-col-span-4">
                    <h4 className=" tw-text-white max-sm:tw-text-lg">
                      {item?.title}
                    </h4>
                    <p className="tw-text-white tw-mt-1 max-sm:tw-text-sm">
                      {item?.excerpt}
                    </p>
                  </div>
                  {/* Image */}
                  <div className="tw-relative tw-size-12 tw-col-span-1 tw-mx-auto !tw-brightness-0 !tw-grayscale !tw-invert">
                    <CCP_TransitionImageChild
                      alt="Icon Image"
                      src={item?.Icon?.url || item?.icon?.url}
                      addedClass="!tw-object-contain"
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : null}
        {/* Button */}
        {content?.button?.text && content?.button?.link ? (
          <>
            <Link
              href={content?.button?.link}
              className="!tw-rounded-full tw-overflow-hidden main-gradient-border tw-w-fit sm:tw-mx-16 tw-mx-auto sm:tw-my-8 tw-my-4"
            >
              <button className="tw-bg-white tw-rounded-full tw-px-4 tw-py-2">
                <h4>{content?.button?.text}</h4>
              </button>
            </Link>
          </>
        ) : null}
      </div>
    </div>
  )
}
