'use client'
import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import SCP_ArabicOverlapseCard from './ArabicOverlapseCard'

export default function CCP_ArabicSwiperOverlapse({ slides }) {
  if (!slides || slides.length === 0) return null

  return (
    <div className="md:tw-mt-16 tw-mt-24">
      <CCP_MySwiper
        breakpoints={{}}
        slidesPerView={1}
        spaceBetween={16}
        swiperClass="highlight-swiper arabic-highlight-swiper"
        watchSlidesProgress={true}
        navigation
        pagination={{ clickable: true }}
        slides={slides.map((slide, i) => (
          <div key={`arabic-highlight-${i}`}>
            <SCP_ArabicOverlapseCard slide={slide} />
          </div>
        ))}
      />
    </div>
  )
}
