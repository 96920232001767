import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { CMS_INSTANCE, NEXT_INSTANCE } from '@/lib/api'
import delayedGetStorage from '@/utils/form/asyncPersist'
import asyncForEach from '@/utils/form/asyncForEach'
import {
  defaultForm,
  defaultRealtimeForm,
} from '@/utils/appointment/defaultForm'
import useMeta from '@/store/meta'
import dayjs from 'dayjs'
import genders from '@/assets/json/epms-genders.json'
import epmsNationalities from '@/assets/json/epms-nationalities.json'

const useAppointment = create(
  persist(
    (set, get) => ({
      form: defaultForm,
      realtimeForm: defaultRealtimeForm,
      // exceptionalForm: defaultForm,
      isSubmitting: false,
      isHNLoading: false,
      isHNLoadingFailed: false,
      isRealtime: false,
      isFullPageLoading: false,
      services: [],
      setForm: (objData) => set({ form: { ...get().form, ...objData } }),
      setRealtimeForm: (objData) =>
        set({ realtimeForm: { ...get().realtimeForm, ...objData } }),
      // setExceptionalForm: (objData) =>
      //   set({ exceptionalForm: { ...get().exceptionalForm, ...objData } }),
      setServices: (services) => set({ services }),
      submitNonRealtimeAppointment: async ({
        submitToken = null,
        fileTokens = [],
      }) => {
        // useGlobal.setState({ isLoading: true })
        set({ isFullPageLoading: true })
        // const { getCenter } = useMeta.getState()
        const form = get().form
        let files = [null, null, null]
        await asyncForEach(form.document_files, async (file, i) => {
          const uploadRes = await useMeta
            .getState()
            .uploadFile(file, fileTokens[i])
          files[i] = uploadRes?.ID
        })

        const data = {
          doctorInfo: {
            drSpecialty: form.doctor_specialty,
            drName: form.doctor_name,
          },
          appointmentInfo: {
            medicalInfo: form.medical_info,
            preferredDate: form.preferred_date,
            preferredTime: form.preferred_time,
            documentFile1: files[0],
            documentFile2: files[1],
            documentFile3: files[2],
            websiteUrl: form?.website_url || 'bangkokhospital.com',
            recipientStaffEmails: form?.recipient_staff_emails,
          },
          contactInfo: {
            contactPersonInfo: {
              name:
                form.contact_person_name ||
                `${form.title} ${form.first_name} ${form.middle_name} ${form.last_name}`,
              phoneNumber:
                form.contact_person_phone_number || form.phone_number,
              email: form.contact_person_email || form.email,
            },
            patientInfo: {
              title: form.title,
              name: `${form.title} ${form.first_name} ${form.middle_name} ${form.last_name}`,
              firstName: form.first_name,
              lastName: form.last_name,
              middleName: form.middle_name,
              email: form.email,
              phoneNumber: form.phone_number,
              dateOfBirth: dayjs(form.date_of_birth)?.format('YYYY-MM-DD'),
              gender:
                genders?.find((x) => x.code == form.gender)?.description_en ||
                form.gender,
              id: form.id,
              nationality:
                epmsNationalities?.find((x) => x.code == form?.nationality)
                  ?.description || form?.nationality,
            },
          },
        }

        try {
          const postId = await CMS_INSTANCE.post('/v1/make-appointment', {
            ...data,
            hospitalCode: form?.code || 'BHQ',
            token: submitToken,
          })

          set({
            isFullPageLoading: false,
            form: {
              ...defaultForm,
              preferred_date: form.preferred_date,
              preferred_time: form.preferred_time,
            },
            realtimeForm: defaultRealtimeForm,
          })

          return {
            status: 'success',
            postId,
          }
        } catch (err) {
          set({ isFullPageLoading: false })
          return {
            status: 'error',
            error: err,
          }
        }
      },
      getUserHN: async (userMe, token, locale = 'th') => {
        const realtimeFormHN = get().realtimeForm.HN
        if (realtimeFormHN) {
          return realtimeFormHN
        }

        set({ isHNLoading: true })
        let userDataForHN = {
          DateOfBirth: userMe?.dob
            ? dayjs(userMe?.dob).format('YYYY-MM-DD')
            : null,
          Email: userMe?.contact_email,
          FirstName: userMe?.first_name,
          LastName: userMe?.last_name,
          MobilePhone: userMe?.contact_phone,
          Nationality: userMe?.nationality,
          PostCode: '999999',
          PreferredLanguage: userMe?.nationality === 'TH' ? 'TH' : 'EN',
          Sex: userMe?.gender,
          Title: userMe?.title,
          token,
        }

        //if id_number digit is less than 5 or use the same character repeatedly, return error
        const isIdNumberInvalid =
          userMe?.id_number?.length < 5 || /^(.)(\1)*$/.test(userMe?.id_number)
        if (isIdNumberInvalid) {
          set({ isHNLoading: false, isHNLoadingFailed: true })
          return { error: 'ID number is invalid' }
        }

        if (userMe?.nationality === 'TH') {
          userDataForHN.NationalID = userMe?.id_number
        } else {
          userDataForHN.PassportNumber = userMe?.id_number
        }

        try {
          const { data } = await NEXT_INSTANCE.post(
            '/next-api/epms/register-patient',
            userDataForHN
          )
          set({ isHNLoading: false })
          if (data?.Result?.HN) {
            useAppointment.getState().setRealtimeForm({
              HN: data?.Result?.HN,
            })
            return data?.Result?.HN
          } else {
            set({ isHNLoadingFailed: true })
            return data
          }
        } catch (err) {
          set({ isHNLoading: false, isHNLoadingFailed: true })
          return err
        }
      },
    }),
    {
      name: '__bgh_appointment',
      version: 2,
      storage: createJSONStorage(() => delayedGetStorage(true)),
      partialize: (state) => ({
        form: state.form,
        realtimeForm: state.realtimeForm,
        isRealtime: state.isRealtime,
      }),
      onRehydrateStorage: (state) => {
        return (state, error) => {
          if (error) {
            // console.log('an error happened during hydration', error)
          } else {
            if (
              state.form.document_files?.length &&
              !state.form.document_files[0].name
            ) {
              // reset document_files
              state.setForm({
                document_files: [],
              })
            }

            //check if preferred_date is before tomorrow, set to null
            if (state?.form?.preferred_date) {
              const today = dayjs().format('YYYY-MM-DD')
              const preferredDate = state.form.preferred_date
              if (dayjs(preferredDate).diff(dayjs(today), 'day') <= 0) {
                state.setForm({
                  preferred_date: null,
                  preferred_date_source: null,
                })
              }
            }
          }
        }
      },
    }
  )
)

export default useAppointment
